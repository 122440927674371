import { useState, useEffect } from 'react';

import EditModalContainer from "../../EditModalContainer";

import Axios from 'axios';

import { useCookies } from 'react-cookie';
import { 
    Skeleton,
    Stack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
} from '@chakra-ui/react';

import EditHeaderForm from './EditHeaderForm';
import { PageHeaderProps } from './EditHeaderForm';

import EditHeaderClienteForm from './EditHeaderClienteForm';
import { PageHeaderClienteProps } from './EditHeaderClienteForm';

interface EditModalHeaderStatus {
    data: PageHeaderProps;
};

interface EditModalHeaderClienteStatus {
    data: PageHeaderClienteProps;
};

interface EditModalBloque2Props {
    isOpen: boolean;
    onClose: () => void;
    pageId: string;
    pageName: string;
    blockName: string;
    isForQuienesSomosPage?: boolean;
    isForClientesPage?: boolean;
    isForDescargasPage?: boolean;
};

const EditHeaderModal = (props: EditModalBloque2Props) => {

    const [ cookie ] = useCookies();

    const [loading, setLoading] = useState(true);

    const [headerStatus, setHeaderStatus ] = useState<EditModalHeaderStatus>({ 
        data: { 
            id: 0,
            img_url: '',
            sub_titulo: '',
            titulo: '',
            titulo2: '',
        }
    });

    const [headerClienteStatus, setHeaderClienteStatus ] = useState<EditModalHeaderClienteStatus>({ 
        data: { 
            id: 0,
            img_url: '',
            sub_titulo: '',
            titulo: '',
            titulo2: '',
            _content: '',
        }
    });

    const [formError, setFormError] = useState({ message: '' });
    const onCloseAlert = (e: any) => {
        setFormError({ message: ''});
    };

    useEffect(() => {
        let source = Axios.CancelToken.source();

        const getData = async () => {
            setLoading(true);
            try {
                let url: string;
                if(props.isForDescargasPage) {
                    url =`${process.env.REACT_APP_URL}/api/paginas/${props.pageId}`;
                } else {
                    url =`${process.env.REACT_APP_URL}/api/${props.pageId}`;
                }

                const res = await Axios.get(url, {
                    cancelToken: source.token,
                    headers: {
                        "Content-type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${cookie.userToken}`
                    }
                });
                
                setLoading(false);

                let data;

                if(props.isForDescargasPage) {
                    data = res.data.data
                } else {
                    data = res.data.data[0];
                };

                if (props.isForClientesPage) {
                    setHeaderClienteStatus({ 
                        data: {
                            id: data.id,
                            titulo: data.titulo,
                            img_url: data.img_url,
                            sub_titulo: data.sub_titulo,
                            titulo2: data.titulo2,
                            _content: data._content,
                        } 
                    });
                } else if(props.isForDescargasPage) {
                    setHeaderStatus({ 
                        data: {
                            titulo: data.titulo,
                            img_url: data.img_url,
                            sub_titulo: data.sub_titulo,
                            titulo2: data.titulo2
                        } 
                    });
                } else {
                    setHeaderStatus({ 
                        data: {
                            id: data.id,
                            titulo: data.titulo,
                            img_url: data.img_url,
                            sub_titulo: data.sub_titulo,
                            titulo2: data.titulo2
                        } 
                    });
                }

            } catch(e: any) {
                setLoading(false);
                if(e instanceof Axios.Cancel) {
                    // Cancelando Axios request
                }else if(typeof e.response.data.message === 'string') {
                    setFormError({
                        message: e.response.data.message ? e.response.data.message : 'Error al obtener los datos del servidor'
                    });
                    console.error("Error getting block data:", e);
                };
            };
        };
        
            
        
        getData();

        return() => {
            setLoading(false);
            source.cancel();
        };
    }, [cookie.userToken, props.isForClientesPage, props.isForDescargasPage, props.pageId]);

    return(
        <EditModalContainer
            isOpen={props.isOpen}
            handleOnClose={props.onClose}
            blockName={props.blockName}
            pageName={props.pageName}
        >
            {
                loading
                ?
                    <Stack>
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                    </Stack>
                :
                    <>
                        {
                            props.isForClientesPage
                            ?
                                <EditHeaderClienteForm
                                    id={headerClienteStatus.data.id}
                                    img_url={headerClienteStatus.data.img_url}
                                    sub_titulo={headerClienteStatus.data.sub_titulo}
                                    titulo={headerClienteStatus.data.titulo}
                                    titulo2={headerClienteStatus.data.titulo2}
                                    _content={headerClienteStatus.data._content}
                                    onClose={props.onClose}
                                />
                            :
                                <EditHeaderForm
                                    id={headerStatus.data.id}
                                    img_url={headerStatus.data.img_url}
                                    sub_titulo={headerStatus.data.sub_titulo}
                                    titulo={headerStatus.data.titulo}
                                    titulo2={headerStatus.data.titulo2}
                                    isForQuienesSomosPage={props.isForQuienesSomosPage}
                                    isForDescargasPage={props.isForDescargasPage}
                                    onClose={props.onClose}
                                />
                        }
                    </>
            }
            {
                formError.message
                ?
                <Alert status='error' mb={6}>
                    <AlertIcon />
                    <AlertTitle mr={2}>Error al enviar el formulario</AlertTitle>
                    <AlertDescription>{formError.message}</AlertDescription>
                    <CloseButton 
                        position='absolute' right='8px' top='8px' 
                        onClick={onCloseAlert}
                    />
                </Alert>
                :
                <></>
            }
        </EditModalContainer>
    );
};

export default EditHeaderModal;