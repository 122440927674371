import { useState, useEffect } from 'react';

import EditModalContainer from "../../EditModalContainer";

import Axios from 'axios';

import { useCookies } from 'react-cookie';
import { 
    Skeleton,
    Stack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    Text,
    AccordionIcon,
    AccordionPanel,
} from '@chakra-ui/react';

import EditBlockForm from './EditBlockForm';

import { PageBlockProps } from './EditBlockForm';

interface EditModalBlockStatus {
    data: Array<PageBlockProps>;
};

interface EditModalBloque2Props {
    isOpen: boolean;
    onClose: () => void;
    pageId: string;
    pageName: string;
    blockName: string;
};

const EditBlockModal = (props: EditModalBloque2Props) => {

    const [ cookie ] = useCookies();

    const [loading, setLoading] = useState(true);

    const [blockStatus, setBlockStatus ] = useState<EditModalBlockStatus>({ 
        data: []
    });

    const [formError, setFormError] = useState({ message: '' });
    const onCloseAlert = (e: any) => {
        setFormError({ message: ''});
    };

    useEffect(() => {
        let source = Axios.CancelToken.source();

        const getData = async () => {
            setLoading(true);
            try {
                const res = await Axios.get(`${process.env.REACT_APP_URL}/api/${props.pageId}`, {
                    cancelToken: source.token,
                    headers: {
                        "Content-type": "application/json",
                        'Authorization': `Bearer ${cookie.userToken}`
                    }
                });
                
                setLoading(false);

                const data = res.data.data[0];

                setBlockStatus({ 
                    data: data.blocks,
                });

            } catch(e: any) {
                setLoading(false);
                if(e instanceof Axios.Cancel) {
                    // Cancelando Axios request
                }else if(typeof e.response.data.message === 'string') {
                    setFormError({
                        message: e.response.data.message ? e.response.data.message : 'Error al obtener los datos del servidor'
                    });
                    console.error("Error getting block data:", e);
                };
            };
        };
        getData();

        return() => {
            setLoading(false);
            source.cancel();
        };
    }, [cookie.userToken, props.pageId]);

    return(
        <EditModalContainer
            isOpen={props.isOpen}
            handleOnClose={props.onClose}
            blockName={props.blockName}
            pageName={props.pageName}
        >
            {
                loading
                ?
                    <Stack>
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                        <Skeleton height='20px' />
                    </Stack>
                :
                <Accordion allowToggle>
                    {blockStatus.data.map((element, i) => {
                        return(
                            <AccordionItem key={i}>
                                <h2>
                                    <AccordionButton>
                                        <Box flex='1' textAlign='left'>
                                            <Text fontSize={24}><b>Bloque {i + 1}</b></Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    
                                    <EditBlockForm
                                        id={element.id}
                                        img_url={element.img_url}
                                        content={element.content}
                                        onClose={props.onClose}
                                    />

                                </AccordionPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            }
            {
                formError.message
                ?
                <Alert status='error' mb={6}>
                    <AlertIcon />
                    <AlertTitle mr={2}>Error al enviar el formulario</AlertTitle>
                    <AlertDescription>{formError.message}</AlertDescription>
                    <CloseButton 
                        position='absolute' right='8px' top='8px' 
                        onClick={onCloseAlert}
                    />
                </Alert>
                :
                <></>
            }
        </EditModalContainer>
    );
};

export default EditBlockModal;