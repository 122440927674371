import { useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import Axios from 'axios';

import { 
    Box,
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
    SimpleGrid,
    ModalFooter,
    Button,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    useToast
} from '@chakra-ui/react';


// props of the component
export interface PageHeaderProps {
    id?: number,
    img_url: string,
    sub_titulo: string,
    titulo: string,
    titulo2: string,
    isForQuienesSomosPage?: boolean;
    isForDescargasPage?: boolean;
    onClose?: Function;
};

// Data that will be send to the api
// Is the same as Slide but without the id
interface Bloque2FormData {
    img_url: File | null;
    sub_titulo: string;
    titulo: string;
    titulo2: string;
};

const EditHeaderForm = (props: PageHeaderProps) => {
    const [loading, setLoading] = useState(false);

    const [ cookie ] = useCookies(['userToken']);

    const [disableSendButton, setDisableSendButton] = useState(false);

    const [formError, setFormError] = useState({ message: '' });

    const toast = useToast();

    const onCloseAlert = (e: any) => {
        setFormError({ message: ''});
    };

    const [headerData, setHeaderData] = useState<Bloque2FormData>({
        img_url: null,
        sub_titulo: props.sub_titulo,
        titulo: props.titulo,
        titulo2: props.titulo2,
    });

    useEffect(() => {
        setHeaderData({
            img_url: null,
            sub_titulo: props.sub_titulo,
            titulo: props.titulo,
            titulo2: props.titulo2,
        });
    }, [props]);

    const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHeaderData({
            ...headerData,
            [e.target.name]: e.target.value,
        });
    };

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(formError) setFormError({ message: '' });
        sendSlideData();
    };

    const handleOnChangeInputImage = (e: any) => {
        setHeaderData({
            ...headerData,
            img_url: e.target.files[0],
        });
    };

    const sendSlideData = async () => {
        setLoading(true);
        setDisableSendButton(true);
        try {
            const fd = new FormData();
            fd.append('sub_titulo', headerData.sub_titulo);
            fd.append('titulo', headerData.titulo);
            fd.append('titulo2', headerData.titulo2);
            if(headerData.img_url)  fd.append('img_url', headerData.img_url as File, headerData.img_url?.name);

            let url: string;
            if(props.isForQuienesSomosPage){
                url = `/api/paginas/updateHeaderQuienesSomos`;
            } else if(props.isForDescargasPage) {
                url = `/api/paginas/updateHeaderDescargas`;
            } else {
                url = `/api/paginas/updateHeaderPage/${props.id}`;
            }

            await Axios.post(`${process.env.REACT_APP_URL}${url}`,
                fd,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        'Authorization': `Bearer ${cookie.userToken}`
                    }
                }
            );

            toast({
                title: 'Formulario enviado exitosamente',
                description: "Se ha actualizado esta parte de la página",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        } catch(e: any) {    // Capturar errores de login
            if(typeof e.response.data.message === 'string') {
                setFormError({
                    message: e.response.data.message
                })
            }
            console.error("EditSliderForm error:", e);
        };
        setLoading(false);
        setDisableSendButton(false);

        if(props.onClose) props.onClose();
    };

    useEffect(() => {
        let isMounted = true;

        if(isMounted && ( headerData.sub_titulo !== '' && 
            headerData.titulo  !== '' &&
            headerData.titulo2 !== ''))
        {
            setDisableSendButton(false);
        } else {
            setDisableSendButton(true);
        };

        return() => {
            isMounted = false;
        };
    }, [headerData]);


    return(
        <form onSubmit={handleOnSubmit}>
            <FormControl>
                <SimpleGrid columns={2} spacing={10}>

                    <Box>
                        <FormLabel htmlFor='titulo'>Titulo del header</FormLabel>
                        <Input 
                            id='titulo' type='text' name='titulo'
                            defaultValue={props.titulo}
                            onChange={handleOnChangeInput}
                            required
                        />
                        <FormHelperText>Titulo que mostrará en el header de la página</FormHelperText>
                    </Box>

                    <Box>
                        <FormLabel htmlFor='titulo2'>Titulo de la sección</FormLabel>
                        <Input 
                            id='titulo2' type='text' name='titulo2'
                            defaultValue={props.titulo2}
                            onChange={handleOnChangeInput}
                            required
                        />
                        <FormHelperText>Titulo que mostrará al principio de la sección principal de la página</FormHelperText>
                    </Box>
                
                    <Box>
                        <FormLabel htmlFor='sub_titulo'>Subtitulo de la sección</FormLabel>
                        <Input 
                            id='sub_titulo' type='text' name='sub_titulo'
                            defaultValue={props.sub_titulo}
                            onChange={handleOnChangeInput}
                            required
                        />
                        <FormHelperText>Subtitulo de la sección principal de la página</FormHelperText>
                    </Box>
                    
                    <Box>
                        <FormLabel htmlFor='img_url'>Fondo del header</FormLabel>
                        <input
                            id='img_url' type='file' name='img_url'
                            onChange={handleOnChangeInputImage}
                            accept="image/png, image/jpeg"

                        />
                        <FormHelperText>Dimensión recomendada de 1920 x 570 pixeles</FormHelperText>
                    </Box>

                </SimpleGrid>
                {
                    formError.message
                    ?
                    <Alert status='error' mb={6}>
                        <AlertIcon />
                        <AlertTitle mr={2}>Error al enviar el formulario</AlertTitle>
                        <AlertDescription>{formError.message}</AlertDescription>
                        <CloseButton 
                            position='absolute' right='8px' top='8px' 
                            onClick={onCloseAlert}
                        />
                    </Alert>
                    :
                    <></>
                }

                <ModalFooter>
                    <Button 
                        colorScheme='blue' type='submit' mr={3}
                        isDisabled={disableSendButton}
                    >
                        {
                            loading
                            ?
                                <Spinner/>
                            :
                            <>Enviar</>
                        }
                    </Button>
                </ModalFooter>
            </FormControl>
        </form>
    );
};

export default EditHeaderForm;