import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageBanner from "../components/PageBanner";

import { useToast } from "@chakra-ui/react";

import Axios from "axios";
import Loader from "../components/Loader";
import HTMLReactParser from "html-react-parser";

interface ProteccionDatosStatus {
    id: number;
    img_url: string;
    sub_titulo: string;
    titulo: string;
    titulo2: string;
    content: string;
};

const ProteccionDatos = () => {

    const [loading, setLoading] = useState(true);
    
    const [proteccionDatosData, setProteccionDatosData] = useState<ProteccionDatosStatus>({ id: 0, content: '', img_url: '', sub_titulo: '', titulo: '', titulo2:'' });

    const toast = useToast();

    useEffect(() => {
        let source = Axios.CancelToken.source();

        const getData = async () => {
            
            try {
                setLoading(true);
                const res = await Axios.get(`${process.env.REACT_APP_URL}/api/avisoPrivacidad`, {
                    cancelToken: source.token,
                    headers: {
                        "Content-type": "application/json",
                    }
                });

                const data = res.data.data[0];

                setProteccionDatosData({
                    id: data.id,
                    content: data.blocks[0].content,
                    img_url: data.img_url,
                    sub_titulo: data.sub_titulo,
                    titulo: data.titulo,
                    titulo2: data.titulo2,
                });

                setLoading(false);

            } catch(e: any) {
                setLoading(false);
                if(e instanceof Axios.Cancel) {
                    // Cancelando Axios request
                }else if(typeof e.response.data.message === 'string') {
                    toast({
                        title: 'Error al conectarse con el servidor',
                        description: e.response.data.message ? e.response.data.message : 'No se han podido obtener los datos de la página',
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                    console.error("Error getting block data:", e);
                };
            };
        };
        getData();

        return() => {
            setLoading(false);
            source.cancel();
        };
    }, [toast]);

    if(loading) {
        return(
            <Loader/>
        );
    } else {
        return(
            <>
                <PageBanner
                    img_url={proteccionDatosData.img_url}
                    titulo={proteccionDatosData.titulo}
                />
                <section className="ssprcessSec listing">
                    <div className="container largeContainer">
                        <div className="row">
                            <div className="text-center col-lg-12">
                                <h2 className="secTitle">{proteccionDatosData.titulo2}</h2>
                                <p>{proteccionDatosData.sub_titulo}</p>
                            </div>
                        </div>
                        <div className="mb-5 row ">
                            <div className="col-lg-12">
                                <div className="absCon">
                                    {HTMLReactParser(proteccionDatosData.content)}
                                </div>
                                <Link to="/clausulaconsentimientoweb" className="mt-6 qu_btn">Cláusula de consentimiento web</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    };
};

export default ProteccionDatos;