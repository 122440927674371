import React from "react";
import Helmet from "react-helmet";
const Loader = () => {
    return(<>
            <Helmet>
                <link rel="stylesheet" href="assets/css/preset.css" />
                <link rel="stylesheet"
                      href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
                      integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn"
                      crossOrigin="anonymous"/>
            </Helmet>
            <div className="text-center preloader clock">
                <div className="queraLoader">
                    <div className="loaderO">
                        <span>G</span>
                        <span>A</span>
                        <span>G</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(Loader);