import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
  } from '@chakra-ui/react'

interface EditModalContainerProps {
    isOpen: boolean;
    handleOnClose: () => void;
    children: React.ReactNode;
    blockName: string;
    pageName: string;
    message?: string;
};

const EditModalContainer = (props: EditModalContainerProps) => {
    return(
        <Modal closeOnOverlayClick={false} isOpen={props.isOpen} onClose={props.handleOnClose} size='3xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {
                props.message
                ?
                  <>
                    {props.message}
                  </>
                :
                  <>
                    {`Modificando sección ${props.blockName} de la página ${props.pageName}`}
                  </>
              }
              </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {props.children}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='red' onClick={props.handleOnClose}>Salir</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    );
};

export default EditModalContainer;