import ReactDOM from 'react-dom';

import App from './routes';

import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
   <CookiesProvider>
      <App />
    </CookiesProvider>,
  document.getElementById('root')
);