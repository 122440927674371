import { useToast } from "@chakra-ui/react";
import Axios from "axios";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";

import PageBanner from "../components/PageBanner";

interface ClausulaConsentimientoStatus {
    id: number;
    img_url: string;
    sub_titulo: string;
    titulo: string;
    titulo2: string;
    content: string;
    file_url: string;
};

const ClausulaConsentimiento = () => {
    const [loading, setLoading] = useState(true);
    
    const [clausulaConsentimientoData, setClausulaConsentimientoData] = useState<ClausulaConsentimientoStatus>({ id: 0, content: '', img_url: '', sub_titulo: '', titulo: '', titulo2:'', file_url: '' });

    const toast = useToast();

    useEffect(() => {
        let source = Axios.CancelToken.source();

        const getData = async () => {
            
            try {
                setLoading(true);
                const res = await Axios.get(`${process.env.REACT_APP_URL}/api/clausulaconosentimientoweb`, {
                    cancelToken: source.token,
                    headers: {
                        "Content-type": "application/json",
                    }
                });

                const data = res.data.data[0];

                setClausulaConsentimientoData({
                    id: data.id,
                    content: data.blocks[0].content,
                    img_url: data.img_url,
                    sub_titulo: data.sub_titulo,
                    titulo: data.titulo,
                    titulo2: data.titulo2,
                    file_url: data.blocks[0].img_url,
                });

                setLoading(false);

            } catch(e: any) {
                setLoading(false);
                if(e instanceof Axios.Cancel) {
                    // Cancelando Axios request
                }else if(typeof e.response.data.message === 'string') {
                    toast({
                        title: 'Error al conectarse con el servidor',
                        description: e.response.data.message ? e.response.data.message : 'No se han podido obtener los datos de la página',
                        status: 'warning',
                        duration: 9000,
                        isClosable: true,
                    });
                    console.error("Error getting block data:", e);
                };
            };
        };
        getData();

        return() => {
            setLoading(false);
            source.cancel();
        };
    }, [toast]);

    if(loading) {
        return(
            <Loader/>
        );
    } else {
        return(
            <>
                <PageBanner
                    img_url={clausulaConsentimientoData.img_url}
                    titulo={clausulaConsentimientoData.titulo}
                />
                <section className="ssprcessSec listing">
                    <div className="container largeContainer">
                        <div className="row">
                            <div className="text-center col-lg-12">
                                <h2 className="secTitle">{clausulaConsentimientoData.titulo2}</h2>
                            </div>
                        </div>
                        <div className="mb-5 row ">
                            <div className="col-lg-12">
                                <div className="absCon">
                                    {HTMLReactParser(clausulaConsentimientoData.content)}
                                </div>
                                <a download target="_blank"  rel="noreferrer" href={`${process.env.REACT_APP_URL}/${clausulaConsentimientoData.file_url}`} className="mt-6 qu_btn">Descargar Consentimiento Web</a>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
};

export default ClausulaConsentimiento;