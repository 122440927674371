import { Container } from "@chakra-ui/react";

import { FiEdit2 } from 'react-icons/fi';

import {
    Button,
    useDisclosure,
    Heading
} from '@chakra-ui/react'

import EditHeaderModal from "../../components/admin/pages/header/EditHeaderModal";
import EditBlockModal from "../../components/admin/pages/block/EditBlockModal";

const AdminProteccionDatos = () => {
    
    const { isOpen: isOpenModalHeader, onOpen: onOpenModalHeader, onClose: onCloseModalHeader } = useDisclosure();
    const { isOpen: isOpenModalBlock, onOpen: onOpenModalBlock, onClose: onCloseModalBlock } = useDisclosure();
    
    return(
        <Container maxW='container.lg'>
            <Heading mb={2}>Privacidad</Heading>
            <hr className="mb-8"></hr>
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            Sección
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            Descripción
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                Header
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            Información general de la página
                                        </td>
                                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                            <div className="flex items-center">
                                                <Button
                                                    colorScheme='blue'
                                                    size='sm'
                                                    onClick={onOpenModalHeader}
                                                >
                                                    <FiEdit2 className="mr-4"/> Editar
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                Bloque
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            Contenido de la página
                                        </td>
                                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                            <div className="flex items-center">
                                                <Button
                                                    colorScheme='blue'
                                                    size='sm'
                                                    onClick={onOpenModalBlock}
                                                >
                                                    <FiEdit2 className="mr-4"/> Editar
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                isOpenModalHeader
                ?
                    <EditHeaderModal
                        isOpen={isOpenModalHeader}
                        onClose={onCloseModalHeader}
                        pageId="avisoPrivacidad"
                        pageName="Privacidad"
                        blockName="Cabezera"
                    />
                :
                    <></>
            }

            {
                isOpenModalBlock
                ?
                    <EditBlockModal
                        isOpen={isOpenModalBlock}
                        onClose={onCloseModalBlock}
                        pageId="avisoPrivacidad"
                        pageName="Privacidad"
                        blockName="Bloque"
                    />
                :
                    <></>
            }

        </Container>
    );
};

export default AdminProteccionDatos;