import { Link, useParams } from "react-router-dom";
import React from "react";

import bannerWithSlug from '../assets/images/bg/banner2.jpg';

export interface PageBannerProps {
    titulo: string,
    img_url: string,
};

const PageBanner = (props: PageBannerProps): JSX.Element => {
    const { slug } = useParams();

    return(
        <section 
            style={{ background: slug 
                ? `url(${bannerWithSlug})`
                : `url(${process.env.REACT_APP_URL}/${props.img_url})`
            }}
            className='page_banner'
        >
            <div className='container largeContainer'>
                <div className="row">
                    <div className="px-1 col-md-6">
                        <h2 style={{ overflowWrap: 'break-word' }} className="font-bold banner-title">{props.titulo}</h2>
                    </div>
                    <div className="text-right col-md-6">
                        <div className="breadcrumbs">
                            <Link to="/" rel="v:url">
                                <i className="twi-home-alt1"></i>
                                Inicio
                            </Link>
                            <span>/</span>{props.titulo}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default React.memo(PageBanner);