import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { Spinner } from "@chakra-ui/react";
import ProteccionDatos from "../pages/ProteccionDatos";
import ClausulaConsentimiento from "../pages/ClausulaConsentimiento";
import AdminProteccionDatos from "../pages/admin/AdminProteccionDatos";
import AdminClausulaConsentimientoWeb from "../pages/admin/AdminClausulaConsentimientoWeb";

const Layout = lazy(() => import("../components/Layout"));
const AsesoriasAdmin = lazy(() => import("../pages/AsesoriasAdmin"));
const AsesoriasFinan = lazy(() => import("../pages/AsesoriasFinan"));
const CreacionEmpre = lazy(() => import("../pages/CreacionEmpre"));
const NormasNiff = lazy(() => import("../pages/NormasNiif"));
const CartelerasDigi = lazy(() => import("../pages/CartelerasDigi"));
const Home = lazy(() => import("../pages/Home"));
const Noticias = lazy(() => import("../pages/Noticias"));
const QuienesSomos = lazy(() => import("../pages/QuienesSomos"));
const Clientes = lazy(() => import("../pages/Clientes"));
const Descargas = lazy(() => import("../pages/Descargas"));
const Contactenos = lazy(() => import("../pages/Contactos"));
const Post = lazy(() => import("../pages/Post"));
const Login = lazy(() => import("../pages/admin/Login"));
const Dashboard = lazy(() => import("../pages/admin/Dashboard"));
const AdminLayout = lazy(() => import("../components/admin/AdminLayout"));
const AdminHome = lazy(() => import("../pages/admin/AdminHome"));
const AdminServices = lazy(() => import("../pages/admin/AdminServices"));
const AdminAsesoriasFinancieras = lazy(() => import("../pages/admin/AdminAsesoriasFinancieras"));
const AdminAsesoriasAdministrativas = lazy(() => import("../pages/admin/AdminAsesoriasAdministrativas"));
const AdminCreacionEmpresas = lazy(() => import("../pages/admin/AdminCreacionEmpresas"));
const AdminCartelerasDigitales = lazy(() => import("../pages/admin/AdminCartelerasDigitales"));
const AdminNormasNiif = lazy(() => import("../pages/admin/AdminNormasNiif"));
const NotFound = lazy(() => import("../components/NotFound"));
const AdminQuienesSomos = lazy(() => import("../pages/admin/AdminQuienesSomos"));
const AdminClientesContenido = lazy(() => import("../pages/admin/AdminClientesContenido"));
const AdminClientes = lazy(() => import("../pages/admin/AdminClientes"));
const AdminDescargasContenido = lazy(() => import("../pages/admin/AdminDescargasContenido"));
const AdminDescargas = lazy(() => import("../pages/admin/AdminDescargas"));
const AdminOwnEmail = lazy(() => import("../pages/admin/AdminOwnEmail"));
const AdminEmails = lazy(() => import("../pages/admin/AdminEmails"));
const AdminBlogCategories = lazy(() => import("../pages/admin/AdminBlogCategories"));
const AdminBlogTags = lazy(() => import("../pages/admin/AdminBlogTags"));
const AdminBlogPosts = lazy(() => import("../pages/admin/AdminBlogPosts"));
const AdminNoticias = lazy(() => import("../pages/admin/AdminNoticias"));
const AdminContactos = lazy(() => import("../pages/admin/AdminContactos"));
const AdminConfiguraciones = lazy(() => import("../pages/admin/AdminConfiguraciones"));

const Router = () => {
    let [cookies] = useCookies(['userToken']);

    return(
        <BrowserRouter>
            <Suspense fallback={
                <div className="flex items-center justify-center w-screen h-screen"><Spinner size={'xl'}/></div>
            }>
                <Routes>
                    <Route element={<Layout />}>
                        <Route path='/' element={<Home />} />
                        <Route path='/asesoriasadministrativas' element={<AsesoriasAdmin />} />
                        <Route path='/asesoriasfinancieras' element={<AsesoriasFinan />} />
                        <Route path='/auditoriayrevisoriafiscal' element={<CreacionEmpre />} />
                        <Route path='/servicioscontables' element={<NormasNiff />} />
                        <Route path='/cartelerasdigitales' element={<CartelerasDigi />} />
                        <Route path='/quienessomos' element={<QuienesSomos />} />
                        <Route path='/clientes' element={<Clientes />} />
                        <Route path='/noticias' element={<Noticias />}/>
                        <Route path='/noticias/:slug' element={<Post/>} />
                        <Route path='/descargas' element={<Descargas />} />
                        <Route path='/contacto' element={<Contactenos />} />
                        <Route path='/protecciondedatos' element={<ProteccionDatos />} />
                        <Route path='/clausulaconsentimientoweb' element={<ClausulaConsentimiento />} />
                    </Route>
                    
                    <Route 
                        path='/login' 
                        element={
                            cookies.userToken
                            ?
                                <Navigate  to='/dashboard'/>
                            :
                                <Login />
                        } 
                    />
                    
                    <Route element={<AdminLayout />}>
                        <Route 
                            path='/dashboard' 
                            element={<Dashboard />} 
                        >
                            
                        </Route>
                        
                        <Route 
                            path='/configuraciones' 
                            element={<AdminConfiguraciones />} 
                        />
                        
                        <Route 
                            path='/dashboard/home' 
                            element={<AdminHome />} 
                        />

                        <Route 
                            path='/dashboard/asesoriasadministrativas' 
                            element={<AdminAsesoriasAdministrativas />} 
                        />

                        <Route 
                            path='/dashboard/asesoriasfinancieras' 
                            element={<AdminAsesoriasFinancieras />} 
                        />

                        <Route 
                            path='/dashboard/cartelerasdigitales'
                            element={<AdminCartelerasDigitales />} 
                        />

                        <Route 
                            path='/dashboard/creaciondeempresas' 
                            element={<AdminCreacionEmpresas />} 
                        />

                        <Route 
                            path='/dashboard/normas-internacionales-niif' 
                            element={<AdminNormasNiif />} 
                        />

                        <Route 
                            path='/dashboard/noticias' 
                            element={<AdminNoticias />} 
                        />

                        <Route
                            path='/dashboard/quienessomos' 
                            element={<AdminQuienesSomos />} 
                        />

                        <Route
                            path='/dashboard/clientescontenido'
                            element={<AdminClientesContenido />} 
                        />

                        <Route
                            path='/dashboard/clientes'
                            element={<AdminClientes />} 
                        />

                        <Route
                            path='/dashboard/descargascontenido'
                            element={<AdminDescargasContenido />}
                        />

                        <Route
                            path='/dashboard/descargas'
                            element={<AdminDescargas />} 
                        />

                        <Route
                            path='/dashboard/contactos' 
                            element={<AdminContactos />} 
                        />

                        <Route
                            path='/dashboard/ownemail'
                            element={<AdminOwnEmail />} 
                        />

                        <Route
                            path='/dashboard/emails'
                            element={<AdminEmails />} 
                        />

                        <Route
                            path='/dashboard/blog/categories'
                            element={<AdminBlogCategories />} 
                        />

                        <Route
                            path='/dashboard/blog/tags'
                            element={<AdminBlogTags />} 
                        />

                        <Route
                            path='/dashboard/blog/posts'
                            element={<AdminBlogPosts />} 
                        />

                        <Route
                            path='/dashboard/services' 
                            element={<AdminServices />} 
                        />

                        <Route
                            path='/dashboard/protecciondedatos'
                            element={<AdminProteccionDatos />} 
                        />

                        <Route
                            path='/dashboard/clausulaconsentimientoweb' 
                            element={<AdminClausulaConsentimientoWeb />} 
                        />

                    </Route>

                    <Route path='*' element={<NotFound/>} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default React.memo(Router);
