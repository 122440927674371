import { useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import Axios from 'axios';

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import modules from '../../../../utils/QuillModules';

import { useLocation } from 'react-router-dom';

import { 
    Box,
    FormControl,
    FormLabel,
    FormHelperText,
    ModalFooter,
    Button,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    useToast
} from '@chakra-ui/react';


// props of the component
export interface PageBlockProps {
    id: number;
    content: string;
    img_url: string;
    onClose: Function;
};

// Data that will be send to the api
// Is the same as Slide but without the id
interface BlockFormData {
    content: string;
    img_url: File | null;
};

const EditBlockForm = (props: PageBlockProps) => {
    const [loading, setLoading] = useState(false);

    const [ cookie ] = useCookies(['userToken']);

    const [disableSendButton, setDisableSendButton] = useState(false);

    const [formError, setFormError] = useState({ message: '' });

    const toast = useToast();

    const location = useLocation();

    const onCloseAlert = (e: any) => {
        setFormError({ message: ''});
    };

    const [blockData, setBlockData] = useState<BlockFormData>({
        content: '',
        img_url: null,
    });

    useEffect(() => {
        setConvertedText(props.content);
    }, [props.content]);

    const [convertedText, setConvertedText] = useState(blockData.content);

    const handleEditorTitleChange = (e) => {
        setConvertedText(e);
        setBlockData({
            ...blockData,
            content: e
        });
    };

    useEffect(() => {
        setBlockData({
            img_url: null,
            content: props.content,
        });
    }, [props]);

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(formError) setFormError({ message: '' });
        sendSlideData();
    };

    const handleOnChangeInputImage = (e: any) => {
        setBlockData({
            ...blockData,
            img_url: e.target.files[0],
        });
    };

    const sendSlideData = async () => {
        setLoading(true);
        setDisableSendButton(true);
        try {
            const fd = new FormData();
            fd.append('_content', blockData.content);
            if(blockData.img_url)  fd.append('img_url', blockData.img_url as File, blockData.img_url?.name);



            await Axios.post(`${process.env.REACT_APP_URL}/api/paginas/updateBlockPage/${props.id}`,
                fd,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        'Authorization': `Bearer ${cookie.userToken}`
                    }
                }
            );

            toast({
                title: 'Formulario enviado exitosamente',
                description: "Se ha actualizado esta parte de la página",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        } catch(e: any) {    // Capturar errores de login
            if(typeof e.response.data.message === 'string') {
                setFormError({
                    message: e.response.data.message
                })
            }
            console.error("EditSliderForm error:", e);
        };
        setLoading(false);
        setDisableSendButton(false);

        props.onClose();
    };

    useEffect(() => {
        let isMounted = true;

        if(isMounted && ( blockData.content !== '')) {
            setDisableSendButton(false);
        } else {
            setDisableSendButton(true);
        };

        return() => {
            isMounted = false;
        };
    }, [blockData]);

    return(
        <form onSubmit={handleOnSubmit}>
            <FormControl>
                <Box mb={12} mt={12}>
                    <FormLabel htmlFor='content_sb'>Contenido del bloque</FormLabel>
                    <ReactQuill
                        theme='snow'
                        value={convertedText}
                        onChange={handleEditorTitleChange}
                        modules={modules}
                        style={{height: '250px', marginBottom: '80px'}}
                    />
                    <FormHelperText>
                        El texto del contenido puede llevar texto enriquecido. Si va a usar imagenes, asegurese que su peso no sobre pase los 600kb, si es el caso, puede comprimir su imagen
                        <a className='text-blue-400' href="https://www.iloveimg.com/es/comprimir-imagen" target='_blank' rel="noreferrer"> aquí</a>.
                    </FormHelperText>
                </Box>
                    
                <Box>
                    {
                        location.pathname === '/dashboard/clausulaconsentimientoweb'
                        ?
                        <>
                            <FormLabel htmlFor='img_url'>Archivo de consentimiento web</FormLabel>
                            <input
                                id='img_url' type='file' name='img_url'
                                onChange={handleOnChangeInputImage}
                                accept="*"

                            />
                        </>
                        :
                        <>
                            <FormLabel htmlFor='img_url'>Imagen del bloque</FormLabel>
                            <input
                                id='img_url' type='file' name='img_url'
                                onChange={handleOnChangeInputImage}
                                accept="video/mp4, image/png, image/jpg, image/jpeg"
                            />
                            
                            <FormHelperText>Dimensión recomendada de 720 x 1280 pixeles</FormHelperText>
                            <FormHelperText>Extensiones permitidas: mp4, png, jpg, jpeg</FormHelperText>
                        </>
                    }
                </Box>

                {
                    formError.message
                    ?
                    <Alert status='error' mb={6}>
                        <AlertIcon />
                        <AlertTitle mr={2}>Error al enviar el formulario</AlertTitle>
                        <AlertDescription>{formError.message}</AlertDescription>
                        <CloseButton 
                            position='absolute' right='8px' top='8px' 
                            onClick={onCloseAlert}
                        />
                    </Alert>
                    :
                    <></>
                }

                <ModalFooter>
                    <Button 
                        colorScheme='blue' type='submit' mr={3}
                        isDisabled={disableSendButton}
                    >
                        {
                            loading
                            ?
                                <Spinner/>
                            :
                            <>Enviar</>
                        }
                    </Button>
                </ModalFooter>
            </FormControl>
        </form>
    );
};

export default EditBlockForm;